@font-face {
    font-family: Ikarus;
    src: url("Ikarus.otf") format("opentype");
}

@font-face{
    font-family: Metron;
    src: url("Metron.otf") format("opentype");
}

.container {
    margin-left: 20px;
    margin-right: 20px; 
    padding: 20; 
    font-family: Courier; 
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(800, auto);
    gap: 20px 20px;
  }